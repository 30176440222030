* {
  box-sizing: border-box;
}
body {
  font-family: 'Silkscreen', cursive;
  margin: 20px;
  background-color: #f7a02f;
  color: #ffeb13;
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
}
