.Game {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
    margin: 2.5rem auto;
    width: 40%;
    height: 70vh;
    color: #5407e4;
    font-size: 8rem;
}
.Game-cell {
    border: solid 4px #04f7ea;
    border-radius: 15px;
    width: 180px;
    height: 180px;
}