.VictoryScreen {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.VictoryScreen-box {
    border: solid 4px #FB2576;
    color: #ffeb13;
    width: 60%;
    height: 50vh;
    background-color: #000000;
}

.VictoryScreen-title {
    font-size: 3rem;
}

.VictoryScreen-player {
    font-size: 3rem;
    margin: 3rem auto;
    font-weight: 900;
}

.VictoryScreen-replay {
    background-color: #9cfc20;
    color: #000000;
    border: none;
    width: 40%;
    height: 6vh;
    border-radius: 15px;
    font-size: 2rem;
    font-family: 'Silkscreen', cursive;
}

.VictoryScreen-replay:hover {
    background-color: #9b184a;
    color: #8beb1e;
}